.bg-clr
{
    background-color: rgb(84,92, 97);
    padding: 30px;
    left: 0px !important;
}
.bottom-aligned-image {
    margin-top: 50px;
    Width:300px ;
    margin-left: 30%;
  }
  .list-unstyled div li{
    margin-bottom: 10px;
  }
  .clr{
    color: rgb(62,153, 211);
  }
  .inline-div {
    display: inline-;
    margin-right: 50px;
  }
  .gradient{
    background: linear-gradient(to right,rgb(22,95, 126), rgb(50,152, 211));

  }
  .footerfont{
    margin-right:20px;
    margin-left:150px;
    font-size: 15px;
    color: rgb(190, 188, 188);
  }
  #img{
    width: '30px';
    height: '30px';
  }
  .input{
    max-width: "280px";
    margin-right: "5px";
  }
  #footer{
    z-index: "-1";
  }
  
  .footer-logo{

  }
  @media (max-width: 767px) {
    .footer-logo img {
      max-width: 70%; 
      height: auto;
    }
  }
  
  