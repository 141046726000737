@media (max-width: 768px) {
  .mobile-view-row {
    flex-direction: row;
  }

  .mobile-view-text {
    font-size: 14px;
  }
  .servicesCol {
    display: flex;
  }
}
@media (min-width: 768px) {
  /* .mobile-view-text {
    
  } */
  .card-title{
    font-size: 13px;
  }
}

.serviceBottom {
  margin-bottom: -30px;
  background-color: white;
  box-shadow : 0px 0px 12px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.cardProduct {
  width: 100%;
}

@media (min-width: 1024px) {
  .card-title {  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* .mobile-view-text {
  } */
  .servicesCol {
    display: flex;
    text-align: center;
    width: auto;
  }
}


.subServices {
  width: 100%;

  white-space: nowrap;
}
.card-body {
  align-items: center !important;
}
.servicesCol {
  display: flex;

  text-align: center;
  width: auto;
}

.mobile-view-text {
  max-width: 100%;
}

.servicesIMG {
  margin-right: 15px;
  width: 25px;
  height: 25px;
}
.servicesListIMG {
  width: auto;
  height: auto;
}
.servicesList {
  background-color: #dbdbdb;
  border: 1px solid white;
  padding: 10px;
  min-height: 170px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  width: auto;
}
.overflow-ellipsis {
  /* overflow: hidden;
  text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

/*  dfvsd */
/* .border-end {
  border-left: 1px;
} */
