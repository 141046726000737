.custom-modal-header .close {
    background-color: red; 
    
  }

  .main-wrapper{
    margin-top: 11rem;
  }
  @media (max-width: 992px) {
    .main-wrapper{
      margin-top: 14rem;
    }
  }