/* Header CSS */
.navbar-brand img {
    height: 70px;
  }
  .navbar {
    height: auto;
    background-color: white;
  }
   .btn {
    border-radius: 20px;
    height: 35px;
    background-color: #37B34A;
  }
  .navbar{
    
  }
  .navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Adjust as needed */
  }