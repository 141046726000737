
.spinner{

}
@media (max-width: 768px) {
    .spinner{
        /* right: 0%;
        left: 0%;
        margin: auto;
        position:absolute; */
        display: flex;
        justify-content: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .loaderDiv{
        overflow: hidden;
    }
  }