/* navbar.css */
.navbar1 {
  /* background: linear-gradient(to right, #ff5722, #ff9800); */
  color: white;
}

.navbar1 .nav-link {
  color: white;
}

.navbar1 .form-control {
  border-radius: 0;
}

.navbar1 .bg-danger {
  border-radius: 0;
}

.navbar1 .rounded-circle {
  width: 30px;
  height: 30px;
}

.navbar1 .text-black {
  color: black;
}
.navbar1 .nav-dropdown-title {
  color: white;
}

.my-button-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: auto;
  left: 50%;
  width: 350px;
  transform: translateX(-80%);
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

.product-overlay {
  position: absolute;
  top: 70%;
  left: 220%;
  width: 650px;
  /* max-width: 650px; */
  margin-left: 50px;
  transform: translateX(-80%);
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}
/* .suggestion-overlay {
  position: absolute;
  top: 100%;
  left: 71.7%;
  width: 607px;
  margin-left: 55px;
  transform: translateX(-80%);
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1000;
} */
/* .suggestion-overlay {
  position: absolute;
  top: 100%;
  left: 71.7%;
  width: 70vw; 
  max-width: 650px; 
  margin-left: 55px;
  transform: translateX(-80%);
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1000;
} */
.suggestion-overlay {
  position: absolute;
  top: auto;
  /* left: 71.7%; */
  width: 70%; 
  max-width: 650px;
  margin-left: 55px;
  transform: translateX(-80%);
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1000;
}


@media (min-width: 992px) and (max-width: 1200px) {
  .suggestion-overlay {
    left: 58%;
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .suggestion-overlay {
    left: 58%;
    width: 80%;
    top: 40px; 
    transform: translateX(-70%);
  }
}
/* @media (min-width: 425px) and (max-width: 768px) {
  .suggestion-overlay {
    left: 58%;
    width: 80%;
    top: 40px; 
    transform: translateX(-70%);
  }
} */
@media (min-width: 426px) and (max-width: 768px) {
  .suggestion-overlay {
    left: 42%;
    width: 200%;
    top: 90px;
    transform: translateX(-50%);
  }
  /* .suggestion-overlay,
  .suggestion-details{
  } */
}
@media (max-width: 426px) {
  .suggestion-overlay {
    left: 33%;
    width: 100%;
    top: 88px;
    transform: translateX(-50%);
  }
  
}
/* @media (min-width: 376px) and (max-width: 425px) {
  .suggestion-overlay {
    left: 47%;
    width: 200%;
    top: 40px;
    transform: translateX(-50%);
  }
 
} */
@media (min-width: 1400px) {
  .suggestion-overlay {
    left: 65.5%;
    width: 90%; 
    /* margin-left: 30px; */
  }
}
@media (min-width: 1201px) and (max-width: 1400px) {
  .suggestion-overlay {
    /* display: none; */
    left: 63.5%;
    width: 90%;
  }
}
/* @media (max-width: 500px) {
  .suggestion-overlay {
  top: 90px;
  left: 22%;
  width: 240px;
  
  transform: translateX(-50%);
  padding: 5px;
  }
} */
@media (max-width: 1399px) {
  .product-overlay {
    position: absolute;
    top: 70%;
    left: 260%;
    width: 650px;
    /* max-width: 650px; */
    margin-left: 50px;
    transform: translateX(-80%);
    color: black;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
  }
}
@media (max-width: 1024px) {
  .product-overlay {
    left: 140%;
    transform: translateX(-50%);
    width: 320%;
    /* max-width: 100%; */
  }
}
@media (max-width: 768px) {
  .product-overlay {
    left: 40%;
    transform: translateX(-50%);
    width: 100%;
    /* max-width: 100%; */
  }
}
@media (max-width: 577px) {
  .product-overlay {
    left: 20%;
    transform: translateX(-50%);
    width: 60%;
    /* max-width: 100%; */
  }
}
.overlay2 {
  position: absolute;
  top: auto;
  right: 0%;
  /* left: 0%; */
  margin-right: 0px;
  width: 300px;
  /* transform: translateX(-75%); */
  color: black;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

@media (max-width: 768px) {
  .overlay2 {
    /* left: 42%; */
    right: 0%;

    /* width: 200%; */
    /* top: 90px; */
    /* transform: translateX(-50%); */
  }
  /* .suggestion-overlay,
  .suggestion-details{
  } */
}
